<template>
  <div>
    <TabTitle class="mb-4" icon="fa-tally">
      <template>API Usage</template>
      <template #sub-title>How many times endpoints have been called in the past month.</template>
    </TabTitle>

    <div class="block block-rounded block-fx-pop">
      <div class="block-content block-content-full">
        <Spinner v-if="loading.getGridfetchUsage" />
        <apexchart v-else height="350" :options="usageChartOptions" :series="usageChartSeries" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Spinner from '@/components/SpinnerLogo';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'AdminGridfetch',
  components: {
    Spinner,
    TabTitle
  },
  data() {
    return {
      fromDate: moment().subtract(30, 'days'),
      toDate: moment()
    };
  },
  computed: {
    ...mapGetters({
      loading: 'admin/loading',
      error: 'admin/error',
      gridfetch: 'admin/gridfetch'
    }),
    dates() {
      const days = this.toDate.diff(this.fromDate, 'days');
      const datesArray = Array.from({ length: days }, (v, i) => moment(this.toDate).subtract(i, 'days').format('YYYY-MM-DD'));

      return datesArray;
    },
    columns() {
      return [
        { name: 'Date', code: 'date' },
        { name: 'API Name', code: 'api' },
        { name: 'API Endpoint', code: 'endpoint' }
      ];
    },
    usageChartSeries() {
      const groupByLabel = this.gridfetch.usage.reduce((acc, curr) => {
        const { label } = curr;
        if (!acc[label]) {
          acc[label] = [];
        }
        acc[label].push(curr);
        return acc;
      }, {});

      const series = Object.keys(groupByLabel).map(label => {
        const data = this.dates.map(date => {
          const item = groupByLabel[label].find(item => item.date === date);
          return item ? item.count : 0;
        });

        return {
          name: label,
          data
        };
      });

      return series;
    },
    usageChartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 200,
          stacked: true,
          toolbar: {
            show: true
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          type: 'datetime',
          categories: this.dates
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        plotOptions: {
          bar: {
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          }
        },
        colors: ['#65C198', '#1F303D', '#F25F5C', '#FFE066', '#1B98E0', '#727272', '#db6edc', '#945ab9', '#25714e', '#941d1c', '#977911', '#045889']
      };
    }
  },
  mounted() {
    this.getGridfetchUsage();
  },
  beforeDestroy() {
    this.clearStore();
  },
  methods: {
    ...mapActions({
      getGridfetchUsage: 'admin/getGridfetchUsage'
    }),
    ...mapMutations({
      clearStore: 'admin/CLEAR_STORE'
    })
  }
};
</script>
